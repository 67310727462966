.maintenance {
  color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}
.maintenance .heading {
  font-size: 1.2rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}
.maintenance span {
  font-weight: 700;
  position: relative;
}

.maintenance span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 50%;
  height: 1px;
  background-color: #fff;
  transform: translateX(50%);
}
.maintenance p {
  --tw-text-opacity: 1;
  color: rgb(237 238 241 / var(--tw-text-opacity));
  margin-bottom: 1.5rem;
  font-size: 0.815rem;
  font-weight: 600;
}

.maintenance .logo {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}
.maintenance .logo img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
