.footer-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.footer-left {
  position: relative;
  color: white;
  flex-grow: 1;
  pointer-events: none;
  display: flex;
  margin-left: 5px;
}

.ticker {
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
}

.ticker marquee {
  display: flex;
  align-items: center;
  width: 100%;
}

.text {
  width: 100%;
}

.text h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.text p {
  font-size: 14px;
  font-weight: 500;
}

.text span {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}
