.userprofile-page {
  /* --tw-bg-opacity: 1;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity));
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden; */
}
.userprofile-page .profile-section {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.userprofile-page .profile-section .profile-img {
  height: 100px;
  width: 100px;
}
.userprofile-page .profile-section .profile-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.userprofile-page .profile-data {
  color: #fff;
  display: flex;
  justify-content: space-around;
  width: 65%;
  margin: auto;
  padding: 30px;
}
.userprofile-page .profile-data .data {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userprofile-page .profile-data .data label {
  --tw-text-opacity: 1;
  color: rgb(83 83 83 / var(--tw-text-opacity));
  font-size: 0.75rem;
  line-height: 1rem;
}
.userprofile-page .profile-data .data span {
  font-weight: 700;
}
.userprofile-page .profile-about {
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(175 175 175 / var(--tw-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  padding: 0 40px 40px 40px;
}

.userprofile-page .posts-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 20px;
    margin: 0 40px;
    padding-bottom: 30px;
}

.userprofile-page .post-data {
  /* Add styling for post items as needed */
}

.userprofile-page .post-img {
    height: 220px;
}
.userprofile-page .post-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}
